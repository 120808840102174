import React, {
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useState,
	useRef,
} from "react";
import {
	Container,
	Form,
	Button,
	ListGroup,
	Badge,
	Row,
	Col,
	InputGroup,
} from "react-bootstrap";
import LeftNavbar from "../components/left-navbar";
import Sticky from "react-stickynode";
import AccountBox from "../components/account-box";
import { ImCheckmark } from "react-icons/im";
import { BsPlusLg } from "react-icons/bs";
import { MdEdit, MdOutlineClear } from "react-icons/md";
import Airtable from "airtable";
import ukLogo from "../images/united kingdom.svg";
import usaLogo from "../images/united states.svg";
import spainLogo from "../images/spain.svg";
import italyLogo from "../images/italy.svg";
import switzerlandLogo from "../images/switzerland.svg";
import { Modal } from "react-bootstrap";
import { Link } from "gatsby";
import "react-quill/dist/quill.snow.css";
import Layout from "../components/layout";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import parse from "html-react-parser";
import { ValidationError } from "@formspree/react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { GatsbySeo } from "gatsby-plugin-next-seo";
const freeEmailDomains = require("free-email-domains");
const ReactQuill = React.lazy(() => import("react-quill"));

const CreateEventPage = () => {
	const [currentStep, setCurrentStep] = useState(1);
	const [eventUCode, setEventUCode] = useState("");
	const [loadForm, setLoadForm] = useState(false);
	const [disableLoad, setDisableLoad] = useState(false);
	const [delegateCrmId, setDelegateCrmId] = useState("");
	const [delegateAirtableId, setDelegateAirtableId] = useState("");
	const [eventUniqueEventId, setEventUniqueEventId] = useState("");
	const [selectedTerm, setSelectedTerm] = useState("");
	const [selectedCancelTerm, setSelectedCancelTerm] = useState("");
	const MAX_SUGGESTIONS = 5;

	const [validated, setValidated] = useState(false);
	const [validated2, setValidated2] = useState(false);
	const formRef = useRef(null);
	const [showValidationError, setShowValidationError] = useState(false);
	const [isEdited, setIsEdited] = useState(false);
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);

	const [secondaryManager, setSecondaryManager] = useState(false);
	const [firstStepComplete, setFirstStepComplete] = useState(false);
	const [secondStepComplete, setSecondStepComplete] = useState(false);
	const [delagateManagerHeading, setDelagateManagerHeading] = useState(
		"Your Delegate Managers"
	);
	const [personalDetailsSectionHeading, setPersonalDetailsSectionHeading] =
		useState("Personal details");
	const [addressSectionHeading, setAddressSectionHeading] =
		useState("Company Address");
	const [contactSectionHeading, setContactSectionHeading] = useState("Contact");
	const [
		termsAndConditionsOfBookingSectionHeading,
		setTermsAndConditionsOfBookingSectionHeading,
	] = useState("Terms and Conditions of Booking");
	const [firstStepLabel, setFirstStepLabel] = useState("Your Details");
	const [secondStepLabel, setSecondStepLabel] = useState("Other Information");
	const [thirdStepLabel, setThirdStepLabel] = useState("Thank You");
	const [titleLabel, setTitleLabel] = useState("Title*");
	const [firstNameLabel, setFirstNameLabel] = useState("First name*");
	const [lastNameLabel, setLastNameLabel] = useState("Last name*");
	const [jobTitleLabel, setJobTitleLabel] = useState("Job title*");
	const [companyNameLabel, setCompanyNameLabel] = useState("Company name*");
	const [address1Label, setAddress1Label] = useState("Address 1*");
	const [address2Label, setAddress2Label] = useState("Address 2");
	const [address3Label, setAddress3Label] = useState("Address 3");
	const [cityLabel, setCityLabel] = useState("City*");
	const [countyLabel, setCountyLabel] = useState("County");
	const [postcodeLabel, setPostcodeLabel] = useState("Postcode*");
	const [countryLabel, setCountryLabel] = useState("Country*");
	const [businessPhoneLabel, setBusinessPhoneLabel] =
		useState("Business phone*");
	const [businessEmailLabel, setBusinessEmailLabel] =
		useState("Business email*");
	const [mobileLabel, setMobileLabel] = useState("Mobile*");
	const [alternateEmailLabel, setAlternateEmailLabel] =
		useState("Alternate email");
	const [understandPolicyLabel, setUnderstandPolicyLabel] = useState(
		"I understand the Cancellation Policy"
	);
	const [initialsLabel, setInitialsLabel] = useState("Initials");
	const [additionalTermsLabel, setAdditionalTermsLabel] = useState(
		"Show additional Terms and Conditions"
	);
	const [iUnderstandLabel, setIUnderstandLabel] = useState(
		`I understand that by clicking on the "Submit" button that this constitutes a firm booking to attend [EVENT NAME] and my agreement to all the Terms and Conditions.`
	);

	const [submitButtonText, setSubmitButtonText] = useState("Submit");

	const [titleErrorMessage, setTitleErrorMessage] = useState(
		"Please provide a valid title."
	);

	const [firstNameErrorMessage, setFirstNameErrorMessage] = useState(
		"Please provide a value."
	);
	const [lastNameErrorMessage, setLastNameErrorMessage] = useState(
		"Please provide a value."
	);
	const [jobTitleErrorMessage, setJobTitleErrorMessage] = useState(
		"Please provide a value."
	);
	const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState(
		"Please provide a value."
	);
	const [address1ErrorMessage, setAddress1ErrorMessage] = useState(
		"Please provide a valid address."
	);
	const [cityErrorMessage, setCityErrorMessage] = useState(
		"Please provide a value."
	);
	const [countyErrorMessage, setCountyErrorMessage] = useState(
		"Please provide a value."
	);
	const [postcodeErrorMessage, setPostcodeErrorMessage] = useState(
		"Please provide a value."
	);
	const [countryErrorMessage, setCountryErrorMessage] = useState(
		"Please provide a value."
	);

	const [businessPhoneErrorMessage, setBusinessPhoneErrorMessage] = useState(
		"Please provide a valid phone number."
	);
	const [businessEmailErrorMessage, setBusinessEmailErrorMessage] = useState(
		"Please enter a valid business email address"
	);
	const [mobileErrorMessage, setMobileErrorMessage] = useState(
		"Please provide a valid phone number."
	);
	const [alternateEmailErrorMessage, setAlternateEmailErrorMessage] = useState(
		"Please provide a valid email."
	);

	const [initialsErrorMessage, setInitialsErrorMessage] = useState(
		"Please provide a value."
	);
	const [thankYouForRegisteringHeading, setThankYouForRegisteringHeading] =
		useState("Thank you for registering your attendance at [EVENT NAME].");
	const [weWillSendYouText, setWeWillSendYouText] = useState(
		"We will send you formal confirmation of your booking very shortly."
	);
	const [reasonHeading, setReasonHeading] = useState("Reason for attendance");
	const [whoElseHeading, setWhoElseHeading] = useState(
		"Who else should attend?"
	);
	const [howDidYouHearLabel, setHowDidYouHearLabel] = useState(
		"How did you first hear about [EVENT NAME]"
	);
	const [whatTriggeredYouLabel, setWhatTriggeredYouLabel] = useState(
		"What triggered you to make the decision to confirm your attendance today?"
	);
	const [
		suggestionsEventRoleErrorMessage,
		setSuggestionsEventRoleErrorMessage,
	] = useState("Please provide a value.");
	const [suggestionsJobTitleErrorMessage, setSuggestionsJobTitleErrorMessage] =
		useState("Please provide a value.");
	const [suggestionsNameErrorMessage, setSuggestionsNameErrorMessage] =
		useState("Please provide a value.");

	const [suggestionsEmailErrorMessage, setSuggestionsEmailErrorMessage] =
		useState("Please enter a valid business email address.");

	const [suggestionsMobileErrorMessage, setSuggestionsMobileErrorMessage] =
		useState("Please provide a valid phone number.");

	const [whoElseDescription, setWhoElseDescription] = useState(
		<div>
			<p>We always welcome recommendations of others to also join us.</p>
			<p>
				If you would like to suggest anyone as a possible delegate, supplier, or
				speaker, please contact me or use the form below.
			</p>
			<p>
				Please make sure you include their name, job title, company, and contact
				details.
			</p>
		</div>
	);
	const [suggestionHeading, setSuggestionHeading] = useState("Suggestion");
	const [submissionCompleteHeading, setSubmissionCompleteHeading] = useState(
		"Submission complete"
	);
	const [submissionCompleteText, setSubmissionCompleteText] = useState(
		<p>
			Thank you for sending us your referrals. I look forward to working with
			you and if I can be of any further assistance, please contact me.
		</p>
	);

	const [bestWishesText, setBestWishesText] = useState("Best wishes");
	const [backToEventPageButton, setBackToEventPageButton] =
		useState("Back to event page");
	const [suggestionEventRoleLabel, setSuggestionEventRoleLabel] =
		useState("Event role*");
	const [suggestionNameLabel, setSuggestionNameLabel] = useState("Name*");
	const [suggestionJobTitleLabel, setSuggestionJobTitleLabel] =
		useState("Job title");
	const [suggestionEmailLabel, setSuggestionEmailLabel] = useState("Email*");
	const [suggestionMobileLabel, setSuggestionMobileLabel] = useState("Mobile");
	const [addSuggestionButton, setAddSuggestionButton] =
		useState("Suggest invitee");
	const [furtherAssistanceText, setFurtherAssistanceText] = useState(
		<div>
			<p>
				If I can be of any further assistance, please don't hesitate to contact
				me.
			</p>
			<p>Best Wishes,</p>
		</div>
	);
	const [eventUniqueId, setEventUniqueId] = useState("");
	const [selectedLanguage, setSelectedLanguage] = useState("English-UK");
	var queryParameters = "";

	var uniqueId = "";
	var delegateId = "";
	var uniqueEId = "";
	var delegateAirtableIdTemp = "";
	const [formData, setFormData] = useState({
		// Initialize your form data fields
		eventId: "",
		eventCode: "",
		participationType: "",
		eventName: "",
		location: "",
		country: "",
		date: "",
		languages: [],
		delegateManagerCode: "",
		delegateManagerName: "",
		delegateManagerPosition: "",
		delegateManagerPhone: "",
		delegateManagerEmail: "",
		secondaryDelegateManagerCode: "",
		secondaryDelegateManagerName: "",
		secondaryDelegateManagerPosition: "",
		secondaryDelegateManagerPhone: "",
		secondaryDelegateManagerEmail: "",
		cancellationDate: "",
		cancellationFee: "",
		forumEmail: "",
		eventRedirectUrl: "",
		// optionalAdditionalTextToNotificationEmail: "",
		thankYouMessage1: "",
		thankYouMessage2: "",
		cancellationTerms: "",
		terms: "",
	});
	const [delegateData, setDelegateData] = useState({
		// Initialize your form data fields
		title: "",
		firstName: "",
		lastName: "",
		jobTitle: "",
		companyName: "",
		address1: "",
		address2: "",
		address3: "",
		city: "",
		county: "",
		postcode: "",
		country: "",
		businessPhone: "",
		businessEmail: "",
		mobile: "",
		alternateEmail: "",
		understandPolicy: false,
		initials: "",
		howDidYouHear: "",
		whatTriggeredYou: "",
		crmContactId: "",
		airtableId: "",
		suggestionId: "",
	});
	const [emailInformation, setEmailInformation] = useState({
		// Initialize your form data fields
		eventName: "",
		suggestions: ``,
	});
	const [suggestions, setSuggestions] = useState([]);

	const suggestionElements = suggestions?.map((suggestion) => (
		<div key={suggestion.id}>
			<p>
				<strong>Event Role:</strong> {suggestion?.eventRole}
			</p>
			<p>
				<strong>Name:</strong> {suggestion?.name}
			</p>
			<p>
				<strong>Job Title:</strong> {suggestion?.jobTitle}
			</p>
			<p>
				<strong>Email:</strong> {suggestion?.email}
			</p>
			<p>
				<strong>Mobile:</strong> {suggestion?.mobile}
			</p>
		</div>
	));

	const handleClose = useCallback(() => setShow(false), []);
	const handleShow = useCallback(() => setShow(true), []);
	const handleClose2 = useCallback(() => setShow2(false), []);
	const handleShow2 = useCallback(() => setShow2(true), []);
	// console.log(uuidv4());
	const addSuggestion = () => {
		if (suggestions?.length < MAX_SUGGESTIONS) {
			const newId = uuidv4();
			setSuggestions([
				...suggestions,
				{
					id: newId,
					eventRole: "",
					name: "",
					jobTitle: "",
					email: "",
					mobile: "",
				},
			]);
		}
	};
	const isValidBusinessEmail = (email) => {
		const domain = email.split("@")[1];

		// console.log(freeEmailDomains);
		// console.log(freeEmailDomains.includes(domain));
		return freeEmailDomains.includes(domain);
	};
	const changeLanguage = (language) => {
		// console.log(language);
		if (language.value === "English-UK" && selectedLanguage !== "English-UK") {
			setSelectedLanguage("English-UK");

			setFurtherAssistanceText(
				<div>
					<p>
						If I can be of any further assistance, please don't hesitate to
						contact me.
					</p>
					<p>Best Wishes,</p>
				</div>
			);
			setDelagateManagerHeading("Your Delegate Managers");
			setPersonalDetailsSectionHeading("Personal details");

			setAddressSectionHeading("Company Address");

			setContactSectionHeading("Contact");

			setTermsAndConditionsOfBookingSectionHeading(
				"Terms and Conditions of Booking"
			);

			setFirstStepLabel("Your Details");
			setSecondStepLabel("Other Information");
			setThirdStepLabel("Thank You");
			setTitleLabel("Title*");
			setFirstNameLabel("First name*");
			setLastNameLabel("Last name*");
			setJobTitleLabel("Job title*");
			setCompanyNameLabel("Company name*");
			setAddress1Label("Address 1*");
			setAddress2Label("Address 2");
			setAddress3Label("Address 3");
			setCityLabel("City*");
			setCountyLabel("County");
			setPostcodeLabel("Postcode*");
			setCountryLabel("Country*");
			setBusinessPhoneLabel("Business phone*");
			setBusinessEmailLabel("Business email*");
			setMobileLabel("Mobile*");
			setAlternateEmailLabel("Alternate email");
			setUnderstandPolicyLabel("I understand the Cancellation Policy");
			setInitialsLabel("Initials");
			setAdditionalTermsLabel("Show additional Terms and Conditions");
			setIUnderstandLabel(
				'I understand that by clicking on the "Submit" button that this constitutes a firm booking to attend [EVENT NAME] and my agreement to all the Terms and Conditions.'
			);
			setSubmitButtonText("Submit");
			setTitleErrorMessage("Please provide a valid value");
			setFirstNameErrorMessage("Please provide a valid value");
			setLastNameErrorMessage("Please provide a valid value");
			setJobTitleErrorMessage("Please provide a valid value");
			setCompanyNameErrorMessage("Please provide a valid value");
			setAddress1ErrorMessage("Please provide a valid value");
			setCityErrorMessage("Please provide a valid value");
			setCountyErrorMessage("Please provide a valid value");
			setPostcodeErrorMessage("Please provide a valid value");
			setCountryErrorMessage("Please provide a valid value");
			setBusinessPhoneErrorMessage("Please provide a valid value");
			setBusinessEmailErrorMessage(
				"Please enter a valid business email address"
			);
			setMobileErrorMessage("Please provide a valid value");
			setInitialsErrorMessage("Please provide a valid value");

			setThankYouForRegisteringHeading(
				"Thank you for registering your attendance at [EVENT NAME]."
			);

			setWeWillSendYouText(
				"We will send you formal confirmation of your booking very shortly."
			);

			setReasonHeading("Reason for attendance");

			setWhoElseHeading("Who else should attend?");

			setHowDidYouHearLabel("How did you first hear about [EVENT NAME]");

			setWhatTriggeredYouLabel(
				"What triggered you to make the decision to confirm your attendance today?"
			);

			setSuggestionsEventRoleErrorMessage("Please provide a value.");

			setSuggestionsJobTitleErrorMessage("Please provide a value.");

			setSuggestionsNameErrorMessage("Please provide a value.");

			setSuggestionsEmailErrorMessage(
				"Please enter a valid business email address."
			);

			setSuggestionsMobileErrorMessage("Please provide a valid phone number.");

			setWhoElseDescription(
				<div>
					<p>We always welcome recommendations of others to also join us.</p>
					<p>
						If you would like to suggest anyone as a possible delegate,
						supplier, or speaker, please contact me or use the form below.
					</p>
					<p>
						Please make sure you include their name, job title, company, and
						contact details.
					</p>
				</div>
			);

			setSuggestionHeading("Suggestion");

			setSubmissionCompleteHeading("Submission complete");

			setSubmissionCompleteText(
				<p>
					Thank you for sending us your referrals. I look forward to working
					with you and if I can be of any further assistance, please contact me.
				</p>
			);

			setBestWishesText("Best wishes");

			setBackToEventPageButton("Back to event page");

			setSuggestionEventRoleLabel("Event role*");

			setSuggestionNameLabel("Name*");

			setSuggestionJobTitleLabel("Job title");

			setSuggestionEmailLabel("Email*");

			setSuggestionMobileLabel("Mobile");
			setAddSuggestionButton("Suggest invitee");
		} else if (
			language.value === "English-US" &&
			selectedLanguage !== "English-US"
		) {
			setSelectedLanguage("English-US");

			setFurtherAssistanceText(
				<div>
					<p>
						If I can be of any further assistance, please don't hesitate to
						contact me.
					</p>
					<p>Best Wishes,</p>
				</div>
			);
			setDelagateManagerHeading("Your Delegate Managers");
			setPersonalDetailsSectionHeading("Personal details");

			setAddressSectionHeading("Company Address");

			setContactSectionHeading("Contact");

			setTermsAndConditionsOfBookingSectionHeading(
				"Terms and Conditions of Booking"
			);

			setFirstStepLabel("Your Details");
			setSecondStepLabel("Other Information");
			setThirdStepLabel("Thank You");
			setTitleLabel("Title*");
			setFirstNameLabel("First name*");
			setLastNameLabel("Last name*");
			setJobTitleLabel("Job title*");
			setCompanyNameLabel("Company name*");
			setAddress1Label("Address 1*");
			setAddress2Label("Address 2");
			setAddress3Label("Address 3");
			setCityLabel("City*");
			setCountyLabel("State");
			setPostcodeLabel("Zip*");
			setCountryLabel("Country*");
			setBusinessPhoneLabel("Business phone*");
			setBusinessEmailLabel("Business email*");
			setMobileLabel("Mobile*");
			setAlternateEmailLabel("Alternate email");
			setUnderstandPolicyLabel("I understand the Cancellation Policy");
			setInitialsLabel("Initials");
			setAdditionalTermsLabel("Show additional Terms and Conditions");
			setIUnderstandLabel(
				'I understand that by clicking on the "Submit" button that this constitutes a firm booking to attend [EVENT NAME] and my agreement to all the Terms and Conditions.'
			);
			setSubmitButtonText("Submit");
			setTitleErrorMessage("Please provide a valid value");
			setFirstNameErrorMessage("Please provide a valid value");
			setLastNameErrorMessage("Please provide a valid value");
			setJobTitleErrorMessage("Please provide a valid value");
			setCompanyNameErrorMessage("Please provide a valid value");
			setAddress1ErrorMessage("Please provide a valid value");
			setCityErrorMessage("Please provide a valid value");
			setCountyErrorMessage("Please provide a valid value");
			setPostcodeErrorMessage("Please provide a valid value");
			setCountryErrorMessage("Please provide a valid value");
			setBusinessPhoneErrorMessage("Please provide a valid value");
			setBusinessEmailErrorMessage(
				"Please enter a valid business email address"
			);
			setMobileErrorMessage("Please provide a valid value");
			setInitialsErrorMessage("Please provide a valid value");

			setThankYouForRegisteringHeading(
				"Thank you for registering your attendance at [EVENT NAME]."
			);

			setWeWillSendYouText(
				"We will send you formal confirmation of your booking very shortly."
			);

			setReasonHeading("Reason for attendance");

			setWhoElseHeading("Who else should attend?");

			setHowDidYouHearLabel("How did you first hear about [EVENT NAME]");

			setWhatTriggeredYouLabel(
				"What triggered you to make the decision to confirm your attendance today?"
			);

			setSuggestionsEventRoleErrorMessage("Please provide a value.");

			setSuggestionsJobTitleErrorMessage("Please provide a value.");

			setSuggestionsNameErrorMessage("Please provide a value.");

			setSuggestionsEmailErrorMessage(
				"Please enter a valid business email address."
			);

			setSuggestionsMobileErrorMessage("Please provide a valid phone number.");

			setWhoElseDescription(
				<div>
					<p>We always welcome recommendations of others to also join us.</p>
					<p>
						If you would like to suggest anyone as a possible delegate,
						supplier, or speaker, please contact me or use the form below.
					</p>
					<p>
						Please make sure you include their name, job title, company, and
						contact details.
					</p>
				</div>
			);

			setSuggestionHeading("Suggestion");

			setSubmissionCompleteHeading("Submission complete");

			setSubmissionCompleteText(
				<p>
					Thank you for sending us your referrals. I look forward to working
					with you and if I can be of any further assistance, please contact me.
				</p>
			);

			setBestWishesText("Best wishes");

			setBackToEventPageButton("Back to event page");

			setSuggestionEventRoleLabel("Event role*");

			setSuggestionNameLabel("Name*");

			setSuggestionJobTitleLabel("Job title");

			setSuggestionEmailLabel("Email*");

			setSuggestionMobileLabel("Mobile");
			setAddSuggestionButton("Suggest invitee");
		} else if (language.value === "Italian" && selectedLanguage !== "Italian") {
			setSelectedLanguage("Italian");

			setFurtherAssistanceText(
				<div>
					<p>
						If I can be of any further assistance, please don't hesitate to
						contact me.
					</p>
					<p>Best Wishes,</p>
				</div>
			);
			setDelagateManagerHeading("Your Delegate Managers");
			setPersonalDetailsSectionHeading("Personal details");

			setAddressSectionHeading("Company Address");

			setContactSectionHeading("Contact");

			setTermsAndConditionsOfBookingSectionHeading(
				"Terms and Conditions of Booking"
			);

			setFirstStepLabel("Your Details");
			setSecondStepLabel("Other Information");
			setThirdStepLabel("Thank You");
			setTitleLabel("Title*");
			setFirstNameLabel("First name*");
			setLastNameLabel("Last name*");
			setJobTitleLabel("Job title*");
			setCompanyNameLabel("Company name*");
			setAddress1Label("Address 1*");
			setAddress2Label("Address 2");
			setAddress3Label("Address 3");
			setCityLabel("City*");
			setCountyLabel("County");
			setPostcodeLabel("Postcode*");
			setCountryLabel("Country*");
			setBusinessPhoneLabel("Business phone*");
			setBusinessEmailLabel("Business email*");
			setMobileLabel("Mobile*");
			setAlternateEmailLabel("Alternate email");
			setUnderstandPolicyLabel("I understand the Cancellation Policy");
			setInitialsLabel("Initials");
			setAdditionalTermsLabel("Show additional Terms and Conditions");
			setIUnderstandLabel(
				'I understand that by clicking on the "Submit" button that this constitutes a firm booking to attend [EVENT NAME] and my agreement to all the Terms and Conditions.'
			);
			setSubmitButtonText("Submit");
			setTitleErrorMessage("Please provide a valid value");
			setFirstNameErrorMessage("Please provide a valid value");
			setLastNameErrorMessage("Please provide a valid value");
			setJobTitleErrorMessage("Please provide a valid value");
			setCompanyNameErrorMessage("Please provide a valid value");
			setAddress1ErrorMessage("Please provide a valid value");
			setCityErrorMessage("Please provide a valid value");
			setCountyErrorMessage("Please provide a valid value");
			setPostcodeErrorMessage("Please provide a valid value");
			setCountryErrorMessage("Please provide a valid value");
			setBusinessPhoneErrorMessage("Please provide a valid value");
			setBusinessEmailErrorMessage(
				"Please enter a valid business email address"
			);
			setMobileErrorMessage("Please provide a valid value");
			setInitialsErrorMessage("Please provide a valid value");

			setThankYouForRegisteringHeading(
				"Thank you for registering your attendance at [EVENT NAME]."
			);

			setWeWillSendYouText(
				"We will send you formal confirmation of your booking very shortly."
			);

			setReasonHeading("Reason for attendance");

			setWhoElseHeading("Who else should attend?");

			setHowDidYouHearLabel("How did you first hear about [EVENT NAME]");

			setWhatTriggeredYouLabel(
				"What triggered you to make the decision to confirm your attendance today?"
			);

			setSuggestionsEventRoleErrorMessage("Please provide a value.");

			setSuggestionsJobTitleErrorMessage("Please provide a value.");

			setSuggestionsNameErrorMessage("Please provide a value.");

			setSuggestionsEmailErrorMessage(
				"Please enter a valid business email address."
			);

			setSuggestionsMobileErrorMessage("Please provide a valid phone number.");

			setWhoElseDescription(
				<div>
					<p>We always welcome recommendations of others to also join us.</p>
					<p>
						If you would like to suggest anyone as a possible delegate,
						supplier, or speaker, please contact me or use the form below.
					</p>
					<p>
						Please make sure you include their name, job title, company, and
						contact details.
					</p>
				</div>
			);

			setSuggestionHeading("Suggestion");

			setSubmissionCompleteHeading("Submission complete");

			setSubmissionCompleteText(
				<p>
					Thank you for sending us your referrals. I look forward to working
					with you and if I can be of any further assistance, please contact me.
				</p>
			);

			setBestWishesText("Best wishes");

			setBackToEventPageButton("Back to event page");

			setSuggestionEventRoleLabel("Event role*");

			setSuggestionNameLabel("Name*");

			setSuggestionJobTitleLabel("Job title");

			setSuggestionEmailLabel("Email*");

			setSuggestionMobileLabel("Mobile");
			setAddSuggestionButton("Suggest invitee");
		} else if (language.value === "Spanish" && selectedLanguage !== "Spanish") {
			setSelectedLanguage("Spanish");

			setFurtherAssistanceText(
				<div>
					<p>
						If I can be of any further assistance, please don't hesitate to
						contact me.
					</p>
					<p>Best Wishes,</p>
				</div>
			);
			setDelagateManagerHeading("Your Delegate Managers");
			setPersonalDetailsSectionHeading("Personal details");

			setAddressSectionHeading("Company Address");

			setContactSectionHeading("Contact");

			setTermsAndConditionsOfBookingSectionHeading(
				"Terms and Conditions of Booking"
			);

			setFirstStepLabel("Your Details");
			setSecondStepLabel("Other Information");
			setThirdStepLabel("Thank You");
			setTitleLabel("Title*");
			setFirstNameLabel("First name*");
			setLastNameLabel("Last name*");
			setJobTitleLabel("Job title*");
			setCompanyNameLabel("Company name*");
			setAddress1Label("Address 1*");
			setAddress2Label("Address 2");
			setAddress3Label("Address 3");
			setCityLabel("City*");
			setCountyLabel("County");
			setPostcodeLabel("Postcode*");
			setCountryLabel("Country*");
			setBusinessPhoneLabel("Business phone*");
			setBusinessEmailLabel("Business email*");
			setMobileLabel("Mobile*");
			setAlternateEmailLabel("Alternate email");
			setUnderstandPolicyLabel("I understand the Cancellation Policy");
			setInitialsLabel("Initials");
			setAdditionalTermsLabel("Show additional Terms and Conditions");
			setIUnderstandLabel(
				'I understand that by clicking on the "Submit" button that this constitutes a firm booking to attend [EVENT NAME] and my agreement to all the Terms and Conditions.'
			);
			setSubmitButtonText("Submit");
			setTitleErrorMessage("Please provide a valid value");
			setFirstNameErrorMessage("Please provide a valid value");
			setLastNameErrorMessage("Please provide a valid value");
			setJobTitleErrorMessage("Please provide a valid value");
			setCompanyNameErrorMessage("Please provide a valid value");
			setAddress1ErrorMessage("Please provide a valid value");
			setCityErrorMessage("Please provide a valid value");
			setCountyErrorMessage("Please provide a valid value");
			setPostcodeErrorMessage("Please provide a valid value");
			setCountryErrorMessage("Please provide a valid value");
			setBusinessPhoneErrorMessage("Please provide a valid value");
			setBusinessEmailErrorMessage(
				"Please enter a valid business email address"
			);
			setMobileErrorMessage("Please provide a valid value");
			setInitialsErrorMessage("Please provide a valid value");

			setThankYouForRegisteringHeading(
				"Thank you for registering your attendance at [EVENT NAME]."
			);

			setWeWillSendYouText(
				"We will send you formal confirmation of your booking very shortly."
			);

			setReasonHeading("Reason for attendance");

			setWhoElseHeading("Who else should attend?");

			setHowDidYouHearLabel("How did you first hear about [EVENT NAME]");

			setWhatTriggeredYouLabel(
				"What triggered you to make the decision to confirm your attendance today?"
			);

			setSuggestionsEventRoleErrorMessage("Please provide a value.");

			setSuggestionsJobTitleErrorMessage("Please provide a value.");

			setSuggestionsNameErrorMessage("Please provide a value.");

			setSuggestionsEmailErrorMessage(
				"Please enter a valid business email address."
			);

			setSuggestionsMobileErrorMessage("Please provide a valid phone number.");

			setWhoElseDescription(
				<div>
					<p>We always welcome recommendations of others to also join us.</p>
					<p>
						If you would like to suggest anyone as a possible delegate,
						supplier, or speaker, please contact me or use the form below.
					</p>
					<p>
						Please make sure you include their name, job title, company, and
						contact details.
					</p>
				</div>
			);

			setSuggestionHeading("Suggestion");

			setSubmissionCompleteHeading("Submission complete");

			setSubmissionCompleteText(
				<p>
					Thank you for sending us your referrals. I look forward to working
					with you and if I can be of any further assistance, please contact me.
				</p>
			);

			setBestWishesText("Best wishes");

			setBackToEventPageButton("Back to event page");

			setSuggestionEventRoleLabel("Event role*");

			setSuggestionNameLabel("Name*");

			setSuggestionJobTitleLabel("Job title");

			setSuggestionEmailLabel("Email*");

			setSuggestionMobileLabel("Mobile");
			setAddSuggestionButton("Suggest invitee");
		} else if (
			language.value === "Swiss-German" &&
			selectedLanguage !== "Swiss-German"
		) {
			setSelectedLanguage("Swiss-German");

			setFurtherAssistanceText(
				<div>
					<p>
						If I can be of any further assistance, please don't hesitate to
						contact me.
					</p>
					<p>Best Wishes,</p>
				</div>
			);
			setDelagateManagerHeading("Your Delegate Managers");
			setPersonalDetailsSectionHeading("Personal details");

			setAddressSectionHeading("Company Address");

			setContactSectionHeading("Contact");

			setTermsAndConditionsOfBookingSectionHeading(
				"Terms and Conditions of Booking"
			);

			setFirstStepLabel("Your Details");
			setSecondStepLabel("Other Information");
			setThirdStepLabel("Thank You");
			setTitleLabel("Title*");
			setFirstNameLabel("First name*");
			setLastNameLabel("Last name*");
			setJobTitleLabel("Job title*");
			setCompanyNameLabel("Company name*");
			setAddress1Label("Address 1*");
			setAddress2Label("Address 2");
			setAddress3Label("Address 3");
			setCityLabel("City*");
			setCountyLabel("County");
			setPostcodeLabel("Postcode*");
			setCountryLabel("Country*");
			setBusinessPhoneLabel("Business phone*");
			setBusinessEmailLabel("Business email*");
			setMobileLabel("Mobile*");
			setAlternateEmailLabel("Alternate email");
			setUnderstandPolicyLabel("I understand the Cancellation Policy");
			setInitialsLabel("Initials");
			setAdditionalTermsLabel("Show additional Terms and Conditions");
			setIUnderstandLabel(
				'I understand that by clicking on the "Submit" button that this constitutes a firm booking to attend [EVENT NAME] and my agreement to all the Terms and Conditions.'
			);
			setSubmitButtonText("Submit");
			setTitleErrorMessage("Please provide a valid value");
			setFirstNameErrorMessage("Please provide a valid value");
			setLastNameErrorMessage("Please provide a valid value");
			setJobTitleErrorMessage("Please provide a valid value");
			setCompanyNameErrorMessage("Please provide a valid value");
			setAddress1ErrorMessage("Please provide a valid value");
			setCityErrorMessage("Please provide a valid value");
			setCountyErrorMessage("Please provide a valid value");
			setPostcodeErrorMessage("Please provide a valid value");
			setCountryErrorMessage("Please provide a valid value");
			setBusinessPhoneErrorMessage("Please provide a valid value");
			setBusinessEmailErrorMessage(
				"Please enter a valid business email address"
			);
			setMobileErrorMessage("Please provide a valid value");
			setInitialsErrorMessage("Please provide a valid value");

			setThankYouForRegisteringHeading(
				"Thank you for registering your attendance at [EVENT NAME]."
			);

			setWeWillSendYouText(
				"We will send you formal confirmation of your booking very shortly."
			);

			setReasonHeading("Reason for attendance");

			setWhoElseHeading("Who else should attend?");

			setHowDidYouHearLabel("How did you first hear about [EVENT NAME]");

			setWhatTriggeredYouLabel(
				"What triggered you to make the decision to confirm your attendance today?"
			);

			setSuggestionsEventRoleErrorMessage("Please provide a value.");

			setSuggestionsJobTitleErrorMessage("Please provide a value.");

			setSuggestionsNameErrorMessage("Please provide a value.");

			setSuggestionsEmailErrorMessage(
				"Please enter a valid business email address."
			);

			setSuggestionsMobileErrorMessage("Please provide a valid phone number.");

			setWhoElseDescription(
				<div>
					<p>We always welcome recommendations of others to also join us.</p>
					<p>
						If you would like to suggest anyone as a possible delegate,
						supplier, or speaker, please contact me or use the form below.
					</p>
					<p>
						Please make sure you include their name, job title, company, and
						contact details.
					</p>
				</div>
			);

			setSuggestionHeading("Suggestion");

			setSubmissionCompleteHeading("Submission complete");

			setSubmissionCompleteText(
				<p>
					Thank you for sending us your referrals. I look forward to working
					with you and if I can be of any further assistance, please contact me.
				</p>
			);

			setBestWishesText("Best wishes");

			setBackToEventPageButton("Back to event page");

			setSuggestionEventRoleLabel("Event role*");

			setSuggestionNameLabel("Name*");

			setSuggestionJobTitleLabel("Job title");

			setSuggestionEmailLabel("Email*");

			setSuggestionMobileLabel("Mobile");
			setAddSuggestionButton("Suggest invitee");
		}
		setSelectedTerm(language.terms.content);
		setSelectedCancelTerm(language.termsCancel.content);
	};

	const removeSuggestion = (id) => {
		setSuggestions(suggestions?.filter((suggestion) => suggestion.id !== id));
	};

	const handleSuggestionChange = (id, field, value) => {
		setSuggestions((prevSuggestions) =>
			prevSuggestions?.map((suggestion) =>
				suggestion.id === id ? { ...suggestion, [field]: value } : suggestion
			)
		);
	};

	useEffect(() => {
		const fetchData = async () => {
			// Check if running in a browser environment
			if (typeof window !== "undefined") {
				const queryParameters = new URLSearchParams(window.location.search);
				const uniqueId = queryParameters.get("code") || "";
				const delegateId = queryParameters.get("crmid") || "";
				const delegateAirtableIdTemp = queryParameters.get("aid") || "";

				// Set state with query parameters
				setDelegateCrmId(delegateId);
				setDelegateAirtableId(delegateAirtableIdTemp);
				setEventUCode(uniqueId);

				try {
					// Fetch data from the Netlify serverless function
					const response = await fetch(
						"/.netlify/functions/invite-event-fetch",
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								"x-api-key": process.env.GATSBY_INVITE_KEY_1, // Replace with your client-side API key
							},
							body: JSON.stringify({ uniqueId }),
						}
					);

					if (!response.ok) {
						const responseJson = await response.json(); // Convert the response to JSON
						// console.log("Error Response:", responseJson); // Log the JSON response
						throw new Error("Error: " + JSON.stringify(responseJson));
					}

					const { eventData } = await response.json();
					if (eventData) {
						// Update state with the fetched event data
						setEventUniqueId(eventData.id);
						setEventUniqueEventId(eventData.uniqueEventId);
						setFormData((prevFormData) => ({
							...prevFormData,
							eventId: eventData.uniqueEventId,
							eventCode: eventData.code,
							participationType: "Delegate",
							eventName: eventData.event,
							location: eventData.venue,
							country: eventData.country,
							date: eventData.date,
							languages: JSON.parse(eventData.languages),
							delegateManagerCode: eventData.dmCode,
							delegateManagerName: eventData.dm,
							delegateManagerPosition: eventData.dmPosition,
							delegateManagerPhone: eventData.dmTelephone,
							delegateManagerEmail: eventData.dmEmail,
							secondaryDelegateManagerCode: eventData.secondaryDmCode,
							secondaryDelegateManagerName: eventData.secondaryDm,
							secondaryDelegateManagerPosition: eventData.secondaryDmPosition,
							secondaryDelegateManagerPhone: eventData.secondaryDmTelephone,
							secondaryDelegateManagerEmail: eventData.secondaryDmEmail,
							cancellationDate: eventData.eventCancellationDate,
							cancellationFee: eventData.eventCancellationFee,
							forumEmail: eventData.eventForumEmail,
							eventRedirectUrl: eventData.eventRedirectUrl,
							thankYouMessage1: eventData.thankYouMessage1,
							thankYouMessage2: eventData.thankYouMessage2,
						}));
						changeLanguage(JSON.parse(eventData.languages)[0]);
					} else {
						throw new Error("Event data not found");
					}
				} catch (err) {
					alert(
						"Could not retrieve data. Please wait 30 seconds and refresh the page if the problem persists please contact your delegate manager"
					);
					// console.error(err);
				}
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = () => {
			return new Promise(async (resolve, reject) => {
				try {
					// Fetch delegate details from Azure using a serverless function
					const delegateResponse = await fetch(
						"/.netlify/functions/invite-handle-delegate-on-load-1",
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								"x-api-key": process.env.GATSBY_INVITE_KEY_2, // Replace with your client-side API key
							},
							body: JSON.stringify({ delegateCrmId }),
						}
					);

					if (!delegateResponse.ok) {
						const responseJson = await delegateResponse.json(); // Convert the response to JSON
						// console.log("Error Response:", responseJson); // Log the JSON response
						throw new Error("Error: " + JSON.stringify(responseJson));
					}
					const delegateDetails = await delegateResponse.json();
					// console.log(delegateDetails);
					// Update state with delegate details
					setDelegateData((prevDelegateData) => ({
						...prevDelegateData,
						title: delegateDetails.title,
						firstName: delegateDetails.firstName,
						lastName: delegateDetails.lastName,
						jobTitle: delegateDetails.jobTitle,
						companyName: delegateDetails.companyName,
						address1: delegateDetails.addressLine1,
						address2: delegateDetails.addressLine2,
						address3: delegateDetails.addressLine3,
						city: delegateDetails.city,
						postcode: delegateDetails.postCode,
						country: delegateDetails.country,
						county: delegateDetails.county,
						businessPhone: delegateDetails.businessPhone,
						businessEmail: delegateDetails.email,
						mobile: delegateDetails.mobilePhone,
						alternateEmail: delegateDetails.alternativeEmail,
						crmContactId: delegateDetails.crmContactId,
					}));

					// Check if an existing record is in Airtable
					const airtableSelectResponse = await fetch(
						"/.netlify/functions/invite-handle-delegate-on-load-3",
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								"x-api-key": process.env.GATSBY_INVITE_KEY_3, // Replace with your client-side API key
							},
							body: JSON.stringify({
								params: {
									options: {
										view: "Grid view",
										filterByFormula: `AND(eventId = "${formData.eventId}", crmContactId = "${delegateDetails.crmContactId}")`,
									},
								},
							}),
						}
					);

					if (!airtableSelectResponse.ok) {
						const responseJson = await airtableSelectResponse.json(); // Convert the response to JSON
						// console.log("Error Response:", responseJson); // Log the JSON response
						throw new Error("Error: " + JSON.stringify(responseJson));
					}
					const existingRecord = await airtableSelectResponse.json();
					// console.log(existingRecord);
					if (existingRecord.length > 0) {
						// Record already exists, handle accordingly
						setDelegateData((prevDelegateData) => ({
							...prevDelegateData,
							airtableId: existingRecord[0].id,
							suggestionId: existingRecord[0].fields.id,
						}));
						if (
							existingRecord[0].fields.status === "Confirmed" &&
							existingRecord[0].fields.suggestedPeople !== "true"
						) {
							setFirstStepComplete(true);
							setCurrentStep(2);
						} else if (
							existingRecord[0].fields.status === "Confirmed" &&
							existingRecord[0].fields.suggestedPeople === "true"
						) {
							setFirstStepComplete(true);
							setSecondStepComplete(true);
							setCurrentStep(3);
						}
					} else {
						// Create a new record in Airtable if it doesn't exist
						const airtableCreateResponse = await fetch(
							"/.netlify/functions/invite-handle-delegate-on-load-2",
							{
								method: "POST",
								headers: {
									"Content-Type": "application/json",
									"x-api-key": process.env.GATSBY_INVITE_KEY_4, // Replace with your client-side API key
								},
								body: JSON.stringify({
									fields: {
										title: delegateDetails.title,
										firstName: delegateDetails.firstName,
										lastName: delegateDetails.lastName,
										jobTitle: delegateDetails.jobTitle,
										companyName: delegateDetails.companyName,
										address1: delegateDetails.addressLine1,
										address2: delegateDetails.addressLine2,
										address3: delegateDetails.addressLine3,
										city: delegateDetails.city,
										postcodeZipcode: delegateDetails.postCode,
										country: delegateDetails.country,
										businessPhone: delegateDetails.businessPhone,
										businessEmail: delegateDetails.email,
										status: "In Progress",
										eventId: eventUniqueEventId,
										mobile: delegateDetails.mobilePhone,
										alternateEmail: delegateDetails.alternativeEmail,
										understandPolicy: delegateData.understandPolicy.toString(),

										crmContactId: delegateDetails.crmContactId,
									},
								}),
							}
						);

						if (!airtableCreateResponse.ok) {
							const responseJson = await airtableCreateResponse.json(); // Convert the response to JSON
							// console.log("Error Response:", responseJson); // Log the JSON response
							throw new Error("Error: " + JSON.stringify(responseJson));
						}
						const newRecord = await airtableCreateResponse.json();
						// console.log(newRecord);
						setDelegateData((prevDelegateData) => ({
							...prevDelegateData,
							airtableId: newRecord[0].id,
							suggestionId: newRecord[0].fields.id,
						}));
						// console.log("working");
					}

					resolve();
				} catch (error) {
					// console.error("Error:", error);
					alert(
						"Could not retrieve data. Please wait 30 seconds and refresh the page if the problem persists please contact your delegate manager"
					);
					reject(error);
				}
			});
		};

		const fetchData2 = () => {
			return new Promise(async (resolve, reject) => {
				try {
					const response = await fetch(
						"/.netlify/functions/invite-handle-delegate-on-load-4",
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								"x-api-key": process.env.GATSBY_INVITE_KEY_5, // Replace with your client-side API key
							},
							body: JSON.stringify({
								recordId: delegateAirtableId,
							}),
						}
					);

					if (!response.ok) {
						const responseJson = await response.json(); // Convert the response to JSON
						// console.log("Error Response:", responseJson); // Log the JSON response
						throw new Error("Error: " + JSON.stringify(responseJson));
					}

					const record = await response.json();
					// console.log(record);
					setDelegateData((prevDelegateData) => ({
						...prevDelegateData,
						airtableId: record.id,
						suggestionId: record.fields.id,
					}));

					setFirstStepComplete(true);
					setCurrentStep(2);

					if (record.fields.suggestedPeople === "true") {
						setSecondStepComplete(true);
						setCurrentStep(3);
					}
					// console.log(currentStep);

					resolve();
				} catch (err) {
					// console.error(err);
					alert(
						"Could not retrieve data. Please wait 30 seconds and refresh the page if the problem persists please contact your delegate manager"
					);
					reject(err);
				}
			});
		};

		if (delegateCrmId !== "" && eventUniqueEventId !== "") {
			const handleLoadForm = async () => {
				try {
					// Wait for all asynchronous operations to complete
					await Promise.all([fetchData()]).then(() => {
						setDisableLoad(true);
					});

					// Set loadForm to true after all operations are complete
				} catch (error) {
					// Handle any errors that occurred during the operations
					// console.error(error);
				}
			};

			// Call handleLoadForm when you want to trigger the delay
			handleLoadForm();
		} else if (delegateAirtableId !== "" && eventUniqueEventId !== "") {
			const handleLoadForm = async () => {
				try {
					// Wait for all asynchronous operations to complete
					await Promise.all([fetchData2()]).then(() => {
						setDisableLoad(true);
					});

					// Set loadForm to true after all operations are complete
				} catch (error) {
					// Handle any errors that occurred during the operations
					// console.error(error);
				}
			};

			// Call handleLoadForm when you want to trigger the delay
			handleLoadForm();
		} else if (eventUniqueEventId !== "" && delegateCrmId === "") {
			setDisableLoad(true);
		}
	}, [eventUniqueEventId]);

	useEffect(() => {
		if (disableLoad === true) {
			setTimeout(() => {
				setLoadForm(true);
			}, 500);
		}
	}, [disableLoad]);
	const createAirtableSuggestions = async () => {
		try {
			const records = suggestions?.map((suggestion) => ({
				fields: {
					eventRole: suggestion.eventRole,
					name: suggestion.name,
					jobTitle: suggestion.jobTitle,
					email: suggestion.email,
					mobile: suggestion.mobile,
					refId: delegateData.suggestionId,
					eventId: formData.eventId,
					uId: suggestion.id,
				},
			}));

			const response = await fetch(
				"/.netlify/functions/invite-create-suggestions",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": process.env.GATSBY_INVITE_KEY_6, // Replace with your client-side API key
					},
					body: JSON.stringify({ records }),
				}
			);

			if (!response.ok) {
				const responseJson = await response.json(); // Convert the response to JSON
				// console.log("Error Response:", responseJson); // Log the JSON response
				throw new Error("Error: " + JSON.stringify(responseJson));
			}

			const airtableRecords = await response.json();
			// console.log("Created Airtable records:", airtableRecords);
		} catch (error) {
			alert(
				"Could update data please wait 30 seconds and try again if the problem persists please contact your delegate manager"
			);
			// console.error("Failed to create Airtable records:", error);
		}
	};

	const handleNextStep = () => {
		setCurrentStep((prevStep) => prevStep + 1);
	};

	const handlePreviousStep = () => {
		setCurrentStep((prevStep) => prevStep - 1);
	};

	const handleChange = (e) => {
		if (e.target.name === "understandPolicy") {
			// console.log(e.target.value);
			const { name, value } = e.target;
			const tempValue = !delegateData.understandPolicy;
			setDelegateData((prevDelegateData) => ({
				...prevDelegateData,
				[name]: tempValue,
			}));
		} else {
			const { name, value } = e.target;

			setDelegateData((prevDelegateData) => ({
				...prevDelegateData,
				[name]: value,
			}));
		}
	};
	const attendeeConfirmation = () => {
		// Your function's code here

		// Push an event to the dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "attendeeConfirmation", // Replace with your custom event name
			eventCategory: "Custom Event", // Optional: Replace with your event category
			eventAction: "Attendee Confirmation", // Optional: Replace with your event action});
		});
	};
	const suggestionsSubmitted = () => {
		// Your function's code here

		// Push an event to the dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "suggestionsSubmitted", // Replace with your custom event name
			eventCategory: "Custom Event", // Optional: Replace with your event category
			eventAction: "Suggestions Submitted", // Optional: Replace with your event action});
		});
	};

	function cleanAndCheckRequiredFields(data, requiredFields) {
		let isAnyFieldEmpty = false;
		let updatedData = { ...data }; // Create a copy of the data to avoid directly mutating the state

		requiredFields.forEach((field) => {
			const value = updatedData[field];
			if (typeof value === "string") {
				if (!value || value.trim() === "") {
					updatedData[field] = value.trim();
					isAnyFieldEmpty = true;
				}
			} else if (!value) {
				isAnyFieldEmpty = true;
			}
		});

		return { isAnyFieldEmpty, updatedData };
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		const form = event.currentTarget;

		if (form.checkValidity() === false) {
			event.stopPropagation();
			setValidated(true);
			return;
		}

		const requiredFields = [
			"address1",
			"title",
			"city",
			"county",
			"companyName",
			"country",
			"businessEmail",
			"firstName",
			"jobTitle",
			"lastName",
			"mobile",
			"postcode",
			"businessPhone",
			"county",
			"initials", // Add or remove any fields as necessary
		];

		const { isAnyFieldEmpty, updatedData } = cleanAndCheckRequiredFields(
			delegateData,
			requiredFields
		);
		setDelegateData(updatedData);

		if (isAnyFieldEmpty) {
			event.stopPropagation();
			setValidated(true);
			return;
		}

		try {
			if (delegateCrmId !== "") {
				const requestData = {
					address1: delegateData.address1,
					address2: delegateData.address2,
					address3: delegateData.address3,
					cityCounty: delegateData.county,
					companyName: delegateData.companyName,
					country: delegateData.country,
					emailLogin: delegateData.businessEmail,
					firstName: delegateData.firstName,
					forumCode: formData.eventCode,
					jobTitle: delegateData.jobTitle,
					lastName: delegateData.lastName,
					mobile: delegateData.mobile,
					participationType: "Delegate",
					postCode: delegateData.postcode,
					workPhone: delegateData.businessPhone,
					title: delegateData.title,
				};
				// console.log(requestData);
				// Post request to Azure API via Netlify function
				const azureResponse = await fetch(
					"/.netlify/functions/invite-handle-submit-step-1",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"x-api-key": process.env.GATSBY_INVITE_KEY_7, // Replace with your client-side API key
						},
						body: JSON.stringify({ requestData }),
					}
				);

				if (!azureResponse.ok) {
					const responseJson = await azureResponse.json(); // Convert the response to JSON
					// console.log("Error Response:", responseJson); // Log the JSON response
					throw new Error("Error: " + JSON.stringify(responseJson));
				}

				// Handle Azure API response
				const azureData = await azureResponse.json();
				// console.log("Azure API response:", azureData);

				// Update Airtable record via Netlify function
				const emailInfo = {
					eventName: formData.eventName,
					eventId: eventUCode,
					forumEmail: formData.forumEmail,
					url: window.location.href,
				};

				const airtableResponse = await fetch(
					"/.netlify/functions/invite-handle-submit-step-3",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"x-api-key": process.env.GATSBY_INVITE_KEY_8, // Replace with your client-side API key
						},
						body: JSON.stringify({
							recordId: delegateData.airtableId,
							updateData: {
								title: delegateData.title,
								firstName: delegateData.firstName,
								lastName: delegateData.lastName,
								jobTitle: delegateData.jobTitle,
								companyName: delegateData.companyName,
								address1: delegateData.address1,
								address2: delegateData.address2,
								address3: delegateData.address3,
								city: delegateData.city,
								county: delegateData.county,
								postcodeZipcode: delegateData.postcode,
								country: delegateData.country,
								businessPhone: delegateData.businessPhone,
								businessEmail: delegateData.businessEmail,
								status: "Confirmed",
								confirmDate: new Date().toLocaleDateString("en-US"),
								mobile: delegateData.mobile,
								alternateEmail: delegateData.alternateEmail,
								understandPolicy: delegateData.understandPolicy.toString(),
								initials: delegateData.initials,
								emailInfo: JSON.stringify(emailInfo),
							},
						}),
					}
				);

				if (!airtableResponse.ok) {
					const responseJson = await airtableResponse.json(); // Convert the response to JSON
					// console.log("Error Response:", responseJson); // Log the JSON response
					throw new Error(
						"Error creating Airtable record: " + JSON.stringify(responseJson)
					);
				}

				handleNextStep();
				attendeeConfirmation();
			} else {
				// Create a new Airtable record via Netlify function
				const emailInfo = {
					eventId: eventUCode,
					eventName: formData.eventName,
					forumEmail: formData.forumEmail,
					url: window.location.href,
				};

				const airtableResponse = await fetch(
					"/.netlify/functions/invite-handle-submit-step-2",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"x-api-key": process.env.GATSBY_INVITE_KEY_9, // Replace with your client-side API key
						},
						body: JSON.stringify({
							fields: {
								title: delegateData.title,
								firstName: delegateData.firstName,
								lastName: delegateData.lastName,
								jobTitle: delegateData.jobTitle,
								companyName: delegateData.companyName,
								address1: delegateData.address1,
								address2: delegateData.address2,
								address3: delegateData.address3,
								city: delegateData.city,
								postcodeZipcode: delegateData.postcode,
								country: delegateData.country,
								businessPhone: delegateData.businessPhone,
								businessEmail: delegateData.businessEmail,
								status: "Confirmed",
								eventId: formData.eventId,
								mobile: delegateData.mobile,
								alternateEmail: delegateData.alternateEmail,
								understandPolicy: delegateData.understandPolicy.toString(),

								confirmDate: new Date().toLocaleDateString("en-US"),
								county: delegateData.county,
								initials: delegateData.initials,
								emailInfo: JSON.stringify(emailInfo),
							},
						}),
					}
				);

				if (!airtableResponse.ok) {
					const responseJson = await airtableResponse.json(); // Convert the response to JSON
					// console.log("Error Response:", responseJson); // Log the JSON response
					throw new Error(
						"Error creating Airtable record: " + JSON.stringify(responseJson)
					);
				}

				const newRecord = await airtableResponse.json();
				// console.log(newRecord[0]);
				setDelegateData((prevDelegateData) => ({
					...prevDelegateData,
					airtableId: newRecord[0].id,
					suggestionId: newRecord[0].fields.id,
				}));
				const newURL = window.location.href + `&aid=${newRecord[0].id}`;
				window.history.pushState({ path: newURL }, "", newURL);
				handleNextStep();
				attendeeConfirmation();
			}

			setFirstStepComplete(true);
			window.scrollTo({ top: 0, behavior: "smooth" });
		} catch (error) {
			// console.error("Error:", error);
			alert(
				"Could not update data. Please wait 30 seconds and try again if the problem persists please contact your delegate manager"
			);
		}

		setValidated(true);
	};

	function cleanAndCheckEmptyFields(objects, requiredFields) {
		let isAnyFieldEmpty = false;

		objects.forEach((obj) => {
			requiredFields.forEach((field) => {
				if (obj[field] && typeof obj[field] === "string") {
					obj[field] = obj[field].trim(); // Clean up the field by trimming whitespace
				}
				if (!obj[field]) {
					isAnyFieldEmpty = true; // Mark as empty if the field is now empty
				}
			});
		});

		return isAnyFieldEmpty;
	}

	const handleSubmit2 = async (event) => {
		event.preventDefault();
		const form = event.currentTarget;

		if (form.checkValidity() === false) {
			event.stopPropagation();
			setValidated2(true);
			return;
		}

		try {
			const emailInfo = {
				eventName: formData.eventName,
				eventId: eventUCode,
				suggestions: suggestions,
				forumEmail: formData.forumEmail,
				url: window.location.href,
			};

			// Update Airtable record via Netlify function
			const airtableResponse = await fetch(
				"/.netlify/functions/invite-handle-submit-2",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": process.env.GATSBY_INVITE_KEY_10, // Replace with your client-side API key
					},
					body: JSON.stringify({
						recordId: delegateData.airtableId,
						updateData: {
							whatTriggeredYou: delegateData.whatTriggeredYou,
							howDidYouHear: delegateData.howDidYouHear,
							emailInfo: JSON.stringify(emailInfo),
							suggestedPeople: "true",
						},
					}),
				}
			);

			if (suggestions?.length > 0) {
				suggestionsSubmitted();
				// Assuming createAirtableSuggestions is another serverless function
				createAirtableSuggestions();
			}
			if (!airtableResponse.ok) {
				const responseJson = await airtableResponse.json(); // Convert the response to JSON
				// console.log("Error Response:", responseJson); // Log the JSON response
				throw new Error(
					"Error creating Airtable record: " + JSON.stringify(responseJson)
				);
			}
			handleNextStep();
			setSecondStepComplete(true);
			window.scrollTo({ top: 0, behavior: "smooth" });
		} catch (error) {
			// console.error("Error:", error);
			alert(
				"Could update data please wait 30 seconds and try again if the problem persists please contact your delegate manager"
			);
		}

		setValidated2(true);
	};

	const handleStepClick = (step) => {
		setCurrentStep(step);
	};

	const renderFormStep = () => {
		switch (currentStep) {
			case 1:
				return (
					<div>
						<Form
							className="delegate-form"
							noValidate
							validated={validated}
							onSubmit={handleSubmit}
						>
							<Row>
								<Col>
									<Row className="align-items-center justify-content-center">
										<Col lg={11}>
											<h2 className="fs-5 ps-md-5">
												{personalDetailsSectionHeading}
											</h2>
											<hr />
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col lg={8}>
									<Row className="gx-2">
										<Form.Group as={Col} md="2" controlId="title">
											<Form.Label className="text-med-grey">
												{titleLabel}
											</Form.Label>
											<Form.Select
												name="title"
												required
												value={delegateData.title}
												onChange={handleChange}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
												}}
											>
												<option value="Air Cdre">Air Cdre</option>
												<option value="AOC">AOC</option>
												<option value="Arch.">Arch.</option>
												<option value="AVM">AVM</option>
												<option value="Avv.">Avv.</option>
												<option value="Baron">Baron</option>
												<option value="Brig. Gen.">Brig. Gen.</option>
												<option value="Capt">Capt</option>
												<option value="Cdre">Cdre</option>
												<option value="Ch Insp">Ch Insp</option>
												<option value="Ch Supt">Ch Supt</option>
												<option value="Chef">Chef</option>
												<option value="Cllr">Cllr</option>
												<option value="Cmdr">Cmdr</option>
												<option value="CMO">CMO</option>
												<option value="Col.">Col.</option>
												<option value="Comm.">Comm.</option>
												<option value="DHR">DHR</option>
												<option value="Don">Don</option>
												<option value="Dott.">Dott.</option>
												<option value="Dott.ssa">Dott.ssa</option>
												<option value="Dr">Dr</option>
												<option value="Egr. Dott.">Egr. Dott.</option>
												<option value="Egr. Ing.">Egr. Ing.</option>
												<option value="Egr. Sig.">Egr. Sig.</option>
												<option value="Father">Father</option>
												<option value="Gen.">Gen.</option>
												<option value="Gent.le">Gent.le</option>
												<option value="Gent.le Dott.">Gent.le Dott.</option>
												<option value="Gent.le Sig.">Gent.le Sig.</option>
												<option value="Gent.le Sig.ra">Gent.le Sig.ra</option>
												<option value="Geom.">Geom.</option>
												<option value="Gp Capt">Gp Capt</option>
												<option value="Hon.">Hon.</option>
												<option value="HRH">HRH</option>
												<option value="Ing.">Ing.</option>
												<option value="Lady">Lady</option>
												<option value="Lord">Lord</option>
												<option value="Lt">Lt</option>
												<option value="Lt Cdr">Lt Cdr</option>
												<option value="Lt Col">Lt Col</option>
												<option value="Madame">Madame</option>
												<option value="Maj.">Maj.</option>
												<option value="Maj. Gen.">Maj. Gen.</option>
												<option value="Miss">Miss</option>
												<option value="Monsieur">Monsieur</option>
												<option value="Mr">Mr</option>
												<option value="Mrs">Mrs</option>
												<option value="Ms">Ms</option>
												<option value="On.">On.</option>
												<option value="Prof.">Prof.</option>
												<option value="Prof. Dr">Prof. Dr</option>
												<option value="Rag.">Rag.</option>
												<option value="Rt Hon.">Rt Hon.</option>
												<option value="Senor">Senor</option>
												<option value="Senora">Senora</option>
												<option value="Sgt.">Sgt.</option>
												<option value="Sig.">Sig.</option>
												<option value="Sig.ra">Sig.ra</option>
												<option value="Sir">Sir</option>
												<option value="Sister">Sister</option>
												<option value="Sqn Ldr">Sqn Ldr</option>
												<option value="Supt.">Supt.</option>
												<option value="The Hon.">The Hon.</option>
												<option value="Wg Cdr">Wg Cdr</option>
											</Form.Select>
											<Form.Control.Feedback type="invalid">
												{titleErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group
											as={Col}
											md={true}
											className="mt-4 mt-md-0"
											controlId="firstName"
										>
											<Form.Label className="text-med-grey">
												{firstNameLabel}
											</Form.Label>

											<Form.Control
												name="firstName"
												onChange={handleChange}
												value={delegateData.firstName}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
												pattern="\S+.*"
												required
											/>
											<Form.Control.Feedback type="invalid">
												{firstNameErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group
											className="mt-4 mt-md-0"
											as={Col}
											md={true}
											controlId="lastName"
										>
											<Form.Label className="text-med-grey">
												{lastNameLabel}
											</Form.Label>

											<Form.Control
												name="lastName"
												onChange={handleChange}
												value={delegateData.lastName}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
												pattern="\S+.*"
												required
											/>
											<Form.Control.Feedback type="invalid">
												{lastNameErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row className="gx-2 mt-4">
										<Form.Group as={Col} md={6} controlId="jobTitle">
											<Form.Label className="text-med-grey">
												{jobTitleLabel}
											</Form.Label>

											<Form.Control
												name="jobTitle"
												onChange={handleChange}
												value={delegateData.jobTitle}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
												pattern="\S+.*"
												required
											/>
											<Form.Control.Feedback type="invalid">
												{jobTitleErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group
											className="mt-4 mt-md-0"
											as={Col}
											md={6}
											controlId="companyName"
										>
											<Form.Label className="text-med-grey">
												{companyNameLabel}
											</Form.Label>

											<Form.Control
												name="companyName"
												onChange={handleChange}
												value={delegateData.companyName}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
												pattern="\S+.*"
												required
											/>
											<Form.Control.Feedback type="invalid">
												{companyNameErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
								</Col>
							</Row>

							<Row>
								<Col>
									<Row className="align-items-center justify-content-center mt-4">
										<Col lg={11}>
											<h2 className="fs-5 ps-md-5">{addressSectionHeading}</h2>
											<hr />
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col lg={8}>
									<Row className="gx-2">
										<Form.Group as={Col} md={6} controlId="address1">
											<Form.Label className="text-med-grey">
												{address1Label}
											</Form.Label>

											<Form.Control
												name="address1"
												onChange={handleChange}
												value={delegateData.address1}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
												pattern="\S+.*"
												required
											/>
											<Form.Control.Feedback type="invalid">
												{address1ErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group
											className="mt-4 mt-md-0"
											as={Col}
											md={6}
											controlId="address2"
										>
											<Form.Label className="text-med-grey">
												{address2Label}
											</Form.Label>

											<Form.Control
												name="address2"
												onChange={handleChange}
												value={delegateData.address2}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
											/>
										</Form.Group>
									</Row>
									<Row className="gx-2 mt-4">
										<Form.Group as={Col} md={6} controlId="address3">
											<Form.Label className="text-med-grey">
												{address3Label}
											</Form.Label>

											<Form.Control
												name="address3"
												onChange={handleChange}
												value={delegateData.address3}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
											/>
										</Form.Group>
										<Form.Group
											className="mt-4 mt-md-0"
											as={Col}
											md={6}
											controlId="city"
										>
											<Form.Label className="text-med-grey">
												{cityLabel}
											</Form.Label>

											<Form.Control
												required
												pattern="\S+.*"
												name="city"
												onChange={handleChange}
												value={delegateData.city}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
											/>
											<Form.Control.Feedback type="invalid">
												{cityErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row className="gx-2 mt-4">
										<Form.Group as={Col} md={6} controlId="county">
											<Form.Label className="text-med-grey">
												{countyLabel}*
											</Form.Label>

											<Form.Control
												required
												pattern="\S+.*"
												name="county"
												onChange={handleChange}
												value={delegateData.county}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
											/>
										</Form.Group>
										<Form.Group
											className="mt-4 mt-md-0"
											as={Col}
											md={6}
											controlId="postcode"
										>
											<Form.Label className="text-med-grey">
												{postcodeLabel}
											</Form.Label>

											<Form.Control
												required
												pattern="\S+.*"
												name="postcode"
												onChange={handleChange}
												value={delegateData.postcode}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
											/>
											<Form.Control.Feedback type="invalid">
												{postcodeErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row className="gx-2 mt-4">
										<Form.Group as={Col} md={12} controlId="country">
											<Form.Label className="text-med-grey">
												{countryLabel}
											</Form.Label>

											<Form.Control
												name="country"
												onChange={handleChange}
												value={delegateData.country}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
												required
												pattern="\S+.*"
											/>
											<Form.Control.Feedback type="invalid">
												{countryErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col>
									<Row className="align-items-center justify-content-center mt-4">
										<Col lg={11}>
											<h2 className="fs-5 ps-md-5">{contactSectionHeading}</h2>
											<hr />
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col lg={8}>
									<Row className="gx-2">
										<Form.Group as={Col} md={6} controlId="businessPhone">
											<Form.Label className="text-med-grey">
												{businessPhoneLabel}
											</Form.Label>

											<Form.Control
												name="businessPhone"
												onChange={handleChange}
												value={delegateData.businessPhone}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="tel"
												pattern="\+?[\d\s]{5,40}"
												required
											/>
											<Form.Control.Feedback type="invalid">
												{businessPhoneErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group
											className="mt-4 mt-md-0"
											as={Col}
											md={6}
											controlId="businessEmail"
										>
											<Form.Label className="text-med-grey">
												{businessEmailLabel}
											</Form.Label>
											<Form.Control
												name="businessEmail"
												onChange={handleChange}
												value={delegateData.businessEmail}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="email"
												required
												pattern=".+@.+" // Basic email format validation
												title="Please enter a valid business email address"
												onBlur={(e) => {
													if (isValidBusinessEmail(e.target.value)) {
														e.target.setCustomValidity(
															"Please enter a valid business email address"
														);
													} else {
														e.target.setCustomValidity("");
													}
												}}
											/>
											<Form.Control.Feedback type="invalid">
												{businessEmailErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row className="gx-2 mt-4">
										<Form.Group as={Col} md={6} controlId="mobile">
											<Form.Label className="text-med-grey">
												{mobileLabel}
											</Form.Label>

											<Form.Control
												required
												name="mobile"
												onChange={handleChange}
												value={delegateData.mobile}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="tel"
												pattern="\+?[\d\s]{5,40}"
											/>
											<Form.Control.Feedback type="invalid">
												{mobileErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group
											className="mt-4 mt-md-0"
											as={Col}
											md={6}
											controlId="alternateEmail"
										>
											<Form.Label className="text-med-grey">
												{alternateEmailLabel}
											</Form.Label>

											<Form.Control
												name="alternateEmail"
												onChange={handleChange}
												value={delegateData.alternateEmail}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="email"
											/>
											<Form.Control.Feedback type="invalid">
												{alternateEmailErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col>
									<Row className="align-items-center justify-content-center mt-5">
										<Col lg={11}>
											<h2 className="fs-5 ps-md-5">
												{termsAndConditionsOfBookingSectionHeading}
											</h2>
											<hr />
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col lg={8}>
									{selectedCancelTerm !== "" && selectedCancelTerm !== null && (
										<div className="terms-container">
											{parse(
												selectedCancelTerm
													?.replace(
														/\[EVENT CANX DATE\]/g,
														formData.cancellationDate
													)
													.replace(
														/\[EVENT CANX FEE\]/g,
														`<span className="fw-bold">${formData.cancellationFee}</span>`
													)
													.replace(/\[EVENT NAME\]/g, formData.eventName) ||
													"loading..."
											)}
										</div>
									)}
									<Row className="justify-content-between align-items-center mt-4">
										<Form.Group as={Col} md={6} controlId="understandPolicy">
											<Form.Check
												onChange={handleChange}
												name="understandPolicy"
												value={delegateData.understandPolicy}
												required
												label={understandPolicyLabel}
											/>
										</Form.Group>

										<Form.Group
											className="mt-4 mt-md-0"
											as={Col}
											md={5}
											controlId="initials"
										>
											<Form.Label className="text-med-grey">
												{initialsLabel}
											</Form.Label>

											<Form.Control
												name="initials"
												onChange={handleChange}
												value={delegateData.initials}
												style={{
													border: "none",
													borderBottom: "1px solid #787878",
													borderRadius: "0px",
													color: "#787878",
												}}
												type="text"
												required
												pattern="\S+.*"
											/>
											<Form.Control.Feedback type="invalid">
												{initialsErrorMessage}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row className="mt-4 mt-md-0">
										<a
											className="text-decoration-underline text-primary"
											style={{ cursor: "pointer" }}
											onClick={handleShow2}
										>
											{additionalTermsLabel}
										</a>
										<p className="my-4">
											{iUnderstandLabel.replace(
												/\[EVENT NAME\]/g,
												formData.eventName
											)}
										</p>
									</Row>
								</Col>
							</Row>
							<div className="text-center">
								<Button
									className="px-5 text-white"
									variant="dark-bg"
									type="submit"
								>
									{submitButtonText}
								</Button>
							</div>
						</Form>
					</div>
				);
			case 2:
				return (
					<>
						<div>
							<Form
								className="delegate-form"
								noValidate
								validated={validated2}
								onSubmit={handleSubmit2}
							>
								<Row>
									<Col>
										<Row className="align-items-center justify-content-center">
											<Col lg={11}>
												<h2 className="fs-5 ps-md-5">
													{thankYouForRegisteringHeading.replace(
														/\[EVENT NAME\]/g,
														formData.eventName
													)}
												</h2>
												<hr />
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className="justify-content-center pb-4">
									<Col lg={8}>
										<p>{formData.thankYouMessage1}</p>
									</Col>
								</Row>
								<div
									style={{ backgroundColor: "#F8F8F8" }}
									className="position-absolute w-100 start-0 d-lg-none pt-4"
								></div>
								<div
									style={{ backgroundColor: "white" }}
									className="w-100 pt-4 d-lg-none "
								></div>
								<div
									style={{ backgroundColor: "#F8F8F8" }}
									className="w-100 pt-4 d-none d-lg-block "
								></div>
								<Row>
									<Col>
										<Row className="align-items-center justify-content-center mt-4">
											<Col lg={11}>
												<h2 className="fs-5 ps-md-5">{reasonHeading}</h2>
												<hr />
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className="justify-content-center">
									<Col lg={8}>
										<Row className="gx-2">
											<Form.Group as={Col} md={12} controlId="howDidYouHear">
												<Form.Label className="text-med-grey">
													{howDidYouHearLabel.replace(
														/\[EVENT NAME\]/g,
														formData.eventName
													)}
												</Form.Label>

												<Form.Control
													name="howDidYouHear"
													onChange={handleChange}
													value={delegateData.howDidYouHear}
													style={{
														border: "none",
														borderBottom: "1px solid #787878",
														borderRadius: "0px",
														color: "#787878",
													}}
													type="text"
												/>
											</Form.Group>
										</Row>
										<Row className="gx-2 mt-4">
											<Form.Group as={Col} md={12} controlId="whatTriggeredYou">
												<Form.Label className="text-med-grey">
													{whatTriggeredYouLabel}
												</Form.Label>

												<Form.Control
													name="whatTriggeredYou"
													onChange={handleChange}
													value={delegateData.whatTriggeredYou}
													style={{
														border: "none",
														borderBottom: "1px solid #787878",
														borderRadius: "0px",
														color: "#787878",
													}}
													type="text"
												/>
											</Form.Group>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col>
										<Row className="align-items-center justify-content-center mt-4">
											<Col lg={11}>
												<h2 className="fs-5 ps-md-5">{whoElseHeading}</h2>
												<hr />
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className="justify-content-center">
									<Col lg={8}>
										<Row>
											<Col>{whoElseDescription}</Col>
										</Row>
										{suggestions?.map((suggestion, index) => (
											<div
												className="position-relative mt-4 pt-4"
												key={suggestion.id}
											>
												<div className="position-absolute  end-0 top-0 ">
													<MdOutlineClear
														style={{ cursor: "pointer" }}
														className="fs-4"
														onClick={() => removeSuggestion(suggestion.id)}
													/>
												</div>{" "}
												<Row className="gx-2 ">
													<Col md={6}>
														<p className="fs-5">
															{suggestionHeading} {index + 1}
														</p>
													</Col>
													<Form.Group
														as={Col}
														md="6"
														controlId={`suggestions${suggestion.id}EventRole`}
													>
														<Form.Label className="text-med-grey">
															{suggestionEventRoleLabel}
														</Form.Label>
														<Form.Select
															name={`suggestions${suggestion.id}EventRole`}
															required
															value={suggestion.eventRole || ""}
															onChange={(e) =>
																handleSuggestionChange(
																	suggestion.id,
																	"eventRole",
																	e.target.value
																)
															}
															style={{
																border: "none",
																borderBottom: "1px solid #787878",
																borderRadius: "0px",
															}}
														>
															<option value="Delegate">Delegate</option>
															<option value="Supplier">Supplier</option>
															<option value="Supplier">Speaker</option>
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{suggestionsEventRoleErrorMessage}
														</Form.Control.Feedback>
													</Form.Group>
												</Row>
												<Row className="gx-2 mt-4">
													<Form.Group
														as={Col}
														md={6}
														controlId={`suggestions${suggestion.id}Name`}
													>
														<Form.Label className="text-med-grey">
															{suggestionNameLabel}
														</Form.Label>

														<Form.Control
															pattern="\S+.*" // This pattern ensures at least one non-whitespace character
															required
															name={`suggestions${suggestion.id}Name`}
															onChange={(e) =>
																handleSuggestionChange(
																	suggestion.id,
																	"name",
																	e.target.value
																)
															}
															value={suggestion.name || ""}
															style={{
																border: "none",
																borderBottom: "1px solid #787878",
																borderRadius: "0px",
																color: "#787878",
															}}
															type="text"
														/>
														<Form.Control.Feedback type="invalid">
															{suggestionsNameErrorMessage}
														</Form.Control.Feedback>
													</Form.Group>
													<Form.Group
														as={Col}
														md={6}
														className="mt-4 mt-md-0"
														controlId={`suggestions${suggestion.id}JobTitle`}
													>
														<Form.Label className="text-med-grey">
															{suggestionJobTitleLabel}
														</Form.Label>

														<Form.Control
															name={`suggestions${suggestion.id}JobTitle`}
															onChange={(e) =>
																handleSuggestionChange(
																	suggestion.id,
																	"jobTitle",
																	e.target.value
																)
															}
															value={suggestion.jobTitle || ""}
															style={{
																border: "none",
																borderBottom: "1px solid #787878",
																borderRadius: "0px",
																color: "#787878",
															}}
															type="text"
														/>
													</Form.Group>
												</Row>
												<Row className="gx-2 mt-5">
													<Form.Group
														as={Col}
														md={6}
														controlId={`suggestions${suggestion.id}Email`}
													>
														<Form.Label className="text-med-grey">
															{suggestionEmailLabel}
														</Form.Label>

														<Form.Control
															name={`suggestions${suggestion.id}Email`}
															onChange={(e) =>
																handleSuggestionChange(
																	suggestion.id,
																	"email",
																	e.target.value
																)
															}
															pattern=".+@.+" // Basic email format validation
															value={suggestion.email || ""}
															title="Please enter a valid business email address"
															style={{
																border: "none",
																borderBottom: "1px solid #787878",
																borderRadius: "0px",
																color: "#787878",
															}}
															type="email"
														/>
														<Form.Control.Feedback type="invalid">
															{suggestionsEmailErrorMessage}
														</Form.Control.Feedback>
													</Form.Group>
													<Form.Group
														as={Col}
														md={6}
														className="mt-4 mt-md-0"
														controlId={`suggestions${suggestion.id}Mobile`}
													>
														<Form.Label className="text-med-grey">
															{suggestionMobileLabel}
														</Form.Label>

														<Form.Control
															name={`suggestions${suggestion.id}Mobile`}
															onChange={(e) =>
																handleSuggestionChange(
																	suggestion.id,
																	"mobile",
																	e.target.value
																)
															}
															value={suggestion.mobile || ""}
															style={{
																border: "none",
																borderBottom: "1px solid #787878",
																borderRadius: "0px",
																color: "#787878",
															}}
															type="tel"
															pattern="\+?[\d\s]{5,40}"
														/>
														<Form.Control.Feedback type="invalid">
															{suggestionsMobileErrorMessage}
														</Form.Control.Feedback>
													</Form.Group>
												</Row>
											</div>
										))}
									</Col>
								</Row>
								{suggestions?.length < MAX_SUGGESTIONS && (
									<div className="text-center">
										<Button
											className="px-5 mt-4 "
											variant="outline-dark-bg"
											onClick={addSuggestion}
										>
											{addSuggestionButton}
										</Button>
									</div>
								)}
								{suggestions?.length === MAX_SUGGESTIONS && (
									<div className="text-center mt-4">
										<p>
											Please contact us directly if you want to suggest more
											people.
										</p>
									</div>
								)}
								<Row className="justify-content-center mt-5">
									<Col lg={8}>
										{furtherAssistanceText}{" "}
										<p className="pb-0 mb-1 ">{formData.delegateManagerName}</p>
										<p className="pb-0 mb-1">
											{formData.delegateManagerPosition}
										</p>
										<div className="d-block">
											<a
												className="black-link "
												href={`tel:${formData.delegateManagerPhone}`}
											>
												{formData.delegateManagerPhone}
											</a>
										</div>
										<div className="d-block">
											<a
												className="black-link d-block"
												href={`tel:${formData.delegateManagerEmail}`}
											>
												{formData.delegateManagerEmail}
											</a>
										</div>
									</Col>
								</Row>

								<div className="text-center">
									<Button
										className="px-5 mt-5 text-white"
										variant="dark-bg"
										type="submit"
									>
										{submitButtonText}
									</Button>
								</div>
							</Form>
						</div>
					</>
				);
			case 3:
				return (
					<>
						<div>
							<Row>
								<Col>
									<Row className="align-items-center justify-content-center">
										<Col lg={11}>
											<h2 className="fs-5 ps-md-5">
												{submissionCompleteHeading}
											</h2>
											<hr />
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col lg={8}>
									<p>{formData.thankYouMessage2}</p>
									<p>
										{bestWishesText}, {formData.delegateManagerName}
									</p>
								</Col>
							</Row>

							<div className="text-center">
								<Button
									className="px-5 mt-5 text-white"
									variant="dark-bg"
									href={formData.eventRedirectUrl}
								>
									{backToEventPageButton}
								</Button>
							</div>
						</div>
					</>
				);

			default:
				return null;
		}
	};

	const renderStepList = () => {
		return (
			<div
				className="w-100 mb-6"
				style={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				{[1, 2, 3].map((step) => (
					<div
						key={step}
						style={{
							display: "flex",
							alignItems: "center",
						}}
						className="position-relative"
					>
						<div
							className="text-white fw-bold"
							style={{
								width: "30px",
								height: "30px",
								borderRadius: "50%",
								backgroundColor: isStepFilled(step)
									? "#2DA8A5"
									: step === currentStep
									? "#255DAB"
									: "#96B6E1",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							{isStepFilled(step) ? (
								<span style={{ fontSize: "17px" }}>
									<ImCheckmark className="text-white pb-1" />
								</span>
							) : (
								step
							)}
						</div>

						<div
							style={{ bottom: "-40px", width: "280px", left: "15px" }}
							className="position-absolute d-md-none  translate-middle-x text-center"
						>
							{currentStep === 1 && step === 1 && (
								<h2 style={{ color: "#787878" }} className="  fs-5">
									{firstStepLabel}
								</h2>
							)}
							{currentStep === 2 && step === 2 && (
								<h2 style={{ color: "#787878" }} className=" fs-5">
									{secondStepLabel}
								</h2>
							)}
							{currentStep === 3 && step === 3 && (
								<h2 style={{ color: "#787878" }} className=" fs-5 ">
									{thirdStepLabel}
								</h2>
							)}
						</div>
						<div
							style={{ bottom: "-50px", width: "280px" }}
							className="position-absolute d-none d-md-block start-0  "
						>
							{step === 1 && (
								<h2 style={{ color: "#787878" }} className="fs-5 ">
									{firstStepLabel}
								</h2>
							)}
							{step === 2 && (
								<h2 style={{ color: "#787878" }} className="fs-5 ">
									{secondStepLabel}
								</h2>
							)}
							{step === 3 && (
								<h2 style={{ color: "#787878" }} className="fs-5  ">
									{thirdStepLabel}
								</h2>
							)}
						</div>

						{step !== 3 && (
							<div>
								<div
									className="d-none d-lg-block"
									style={{
										width: "20vw",
										height: "4px",
										background: "rgba(0, 0, 0, 0.32)",
									}}
								></div>
								<div
									className=" d-none d-md-block d-lg-none"
									style={{
										width: "25vw",
										height: "4px",
										background: "rgba(0, 0, 0, 0.32)",
									}}
								></div>
								<div
									className=" d-md-none "
									style={{
										width: "13vw",
										height: "4px",
										background: "rgba(0, 0, 0, 0.32)",
									}}
								></div>
							</div>
						)}
					</div>
				))}
			</div>
		);
	};

	const isStepFilled = (step) => {
		switch (step) {
			case 1:
				return firstStepComplete;
			case 2:
				return secondStepComplete;
			case 3:
				return false;
			case 4:
				return false;
			case 5:
				return false;
			default:
				return false;
		}
	};
	const isStepFieldFilled = (...fields) => {
		return fields.filter((field) => field !== "").length % 2 === 1;
	};

	const isAllStepFieldsFilled = (step) => {
		switch (step) {
			case 1:
				return isStepFieldFilled(
					formData.eventId,

					formData.eventName,
					formData.location,
					formData.country,
					formData.date,
					formData.language
				);
			case 2:
				return isStepFieldFilled(
					formData.delegateManagerCode,
					formData.delegateManagerName,
					formData.delegateManagerPosition,
					formData.delegateManagerPhone,
					formData.delegateManagerEmail
				);
			case 3:
				return isStepFieldFilled(false);
			case 4:
				return isStepFieldFilled(formData.cancellationTerms, formData.terms);
			case 5:
				return false;
			default:
				return false;
		}
	};

	return (
		<Layout>
			<GatsbySeo title="Invite" language="en" noindex nofollow />
			<Navigation />
			<section>
				<div style={{ background: "#F8F8F8" }}>
					<Container className="px-4 px-lg-6 py-4" fluid>
						<Button
							className="text-white d-flex d-xl-none align-items-center justify-content-center"
							variant="green"
							style={{
								position: "fixed",
								bottom: "20px",
								right: "20px",
								zIndex: 9999,
								borderRadius: "50%",
								width: "70px",
								height: "70px",
							}}
							onClick={handleShow}
						>
							<IoMdInformationCircleOutline
								style={{ width: "40px", height: "40px" }}
							/>
						</Button>
						<Modal
							style={{ zIndex: 99999 }}
							size="lg"
							show={show}
							onHide={handleClose}
							centered
						>
							<Modal.Body className=" p-4" closeButton>
								<div className="position-relative">
									<div className="position-absolute  end-0 top-0 ">
										<MdOutlineClear
											style={{ cursor: "pointer" }}
											className="fs-3"
											onClick={handleClose}
										/>
									</div>
									<div
										style={{ borderRadius: "8px" }}
										className=" bg-white px-4 py-5"
									>
										<h1 className="fs-5 fw-bold mb-4">
											{formData.eventName || <Skeleton />}
										</h1>
										<p className="text-uppercase">
											{formData.location || <Skeleton />}
										</p>
										<p className="text-uppercase">
											{formData.date || <Skeleton />}
										</p>
										{formData?.languages?.find(
											(language) => language.value === "English-UK"
										) && (
											<div
												style={{ cursor: "pointer" }}
												onClick={() => {
													const foundLanguage = formData.languages.find(
														(language) => language.value === "English-UK"
													);
													changeLanguage(foundLanguage);
												}}
												className="d-flex align-items-center"
											>
												<img
													className="me-2"
													style={{ width: "20px" }}
													src={ukLogo}
													alt="UK Flag"
												/>
												<p
													className={`pb-0 mb-0 ${
														selectedLanguage === "English-UK"
															? "text-green fw-bold"
															: ""
													}`}
												>
													English-UK
												</p>
											</div>
										)}
										{formData?.languages?.find(
											(language) => language.value === "English-US"
										) && (
											<div
												style={{ cursor: "pointer" }}
												onClick={() => {
													const foundLanguage = formData.languages.find(
														(language) => language.value === "English-US"
													);
													changeLanguage(foundLanguage);
												}}
												className="d-flex mt-2 align-items-center"
											>
												<img
													className="me-2"
													style={{ width: "20px" }}
													src={usaLogo}
													alt="USA Flag"
												/>
												<p
													className={`pb-0 mb-0 ${
														selectedLanguage === "English-US"
															? "text-green fw-bold"
															: ""
													}`}
												>
													English-US
												</p>
											</div>
										)}
										{formData?.languages?.find(
											(language) => language.value === "Italian"
										) && (
											<div
												style={{ cursor: "pointer" }}
												onClick={() => {
													const foundLanguage = formData.languages.find(
														(language) => language.value === "Italian"
													);
													changeLanguage(foundLanguage);
												}}
												className="d-flex mt-2 align-items-center"
											>
												<img
													className="me-2"
													style={{ width: "20px" }}
													src={italyLogo}
													alt="Italian Flag"
												/>
												<p
													className={`pb-0 mb-0 ${
														selectedLanguage === "Italian"
															? "text-green fw-bold"
															: ""
													}`}
												>
													Italian
												</p>
											</div>
										)}
										{formData?.languages?.find(
											(language) => language.value === "Spanish"
										) && (
											<div
												style={{ cursor: "pointer" }}
												onClick={() => {
													const foundLanguage = formData.languages.find(
														(language) => language.value === "Spanish"
													);
													changeLanguage(foundLanguage);
												}}
												className="d-flex mt-2 align-items-center"
											>
												<img
													className="me-2"
													style={{ width: "20px" }}
													src={spainLogo}
													alt="Spanish Flag"
												/>
												<p
													className={`pb-0 mb-0 ${
														selectedLanguage === "Spanish"
															? "text-green fw-bold"
															: ""
													}`}
												>
													Spanish
												</p>
											</div>
										)}
										{formData?.languages?.find(
											(language) => language.value === "Swiss-German"
										) && (
											<div
												style={{ cursor: "pointer" }}
												onClick={() => {
													const foundLanguage = formData.languages.find(
														(language) => language.value === "Swiss-German"
													);
													changeLanguage(foundLanguage);
												}}
												className="d-flex mt-2 align-items-center"
											>
												<img
													className="me-2"
													style={{ width: "20px" }}
													src={switzerlandLogo}
													alt="Switzerland Flag"
												/>
												<p
													className={`pb-0 mb-0 ${
														selectedLanguage === "Swiss-German"
															? "text-green fw-bold"
															: ""
													}`}
												>
													Swiss-German
												</p>
											</div>
										)}
										<h2 className="fs-5 mb-3 fw-bold mt-5 text-blue-new">
											{delagateManagerHeading || <Skeleton />}
										</h2>
										<p className="pb-0 mb-1 fw-bold">
											{formData.delegateManagerName || <Skeleton />}
										</p>
										<p className="pb-0 mb-1">
											{formData.delegateManagerPosition || <Skeleton />}
										</p>
										<a
											className="black-link d-block"
											href={`tel:${formData.delegateManagerPhone}`}
										>
											{formData.delegateManagerPhone || <Skeleton />}
										</a>
										<a
											className="black-link d-block"
											href={`mailto:${formData.delegateManagerEmail}`}
										>
											{formData.delegateManagerEmail || <Skeleton />}
										</a>
										{formData.secondaryDelegateManagerCode !== "" && (
											<div className="mt-4">
												<p className="pb-0 mb-1 fw-bold">
													{formData.secondaryDelegateManagerName}
												</p>
												<p className="pb-0 mb-1">
													{formData.secondaryDelegateManagerPosition}
												</p>
												<a
													className="black-link d-block"
													href={`tel:${formData.secondaryDelegateManagerPhone}`}
												>
													{formData.secondaryDelegateManagerPhone}
												</a>
												<a
													className="black-link d-block"
													href={`mailto:${formData.secondaryDelegateManagerEmail}`}
												>
													{formData.secondaryDelegateManagerEmail}
												</a>
											</div>
										)}
									</div>
								</div>
							</Modal.Body>
						</Modal>
						<Modal
							style={{ zIndex: 99999 }}
							size="lg"
							show={show2}
							onHide={handleClose2}
							centered
						>
							<Modal.Body className=" p-4" closeButton>
								<div className="position-relative">
									<div className="position-absolute  end-0 top-0 ">
										<MdOutlineClear
											style={{ cursor: "pointer" }}
											className="fs-3"
											onClick={handleClose2}
										/>
									</div>
									<div
										style={{ borderRadius: "8px" }}
										className=" bg-white px-4 py-5"
									>
										{selectedTerm !== "" && selectedTerm !== null && (
											<div className="terms-container">
												{parse(
													selectedTerm
														?.replace(
															/\[EVENT CANX DATE\]/g,
															formData.cancellationDate
														)
														.replace(
															/\[EVENT CANX FEE\]/g,
															`<span className="fw-bold">${formData.cancellationFee}</span>`
														)
														.replace(/\[EVENT NAME\]/g, formData.eventName) ||
														"loading..."
												)}
											</div>
										)}
									</div>
								</div>
							</Modal.Body>
						</Modal>
						<Row>
							<Col className="d-none d-xl-block" xl={3}>
								<div
									style={{ borderRadius: "8px" }}
									className=" bg-white px-4 py-5"
								>
									<h1 className="fs-5 fw-bold mb-4">
										{formData.eventName || <Skeleton />}
									</h1>
									<p className="text-uppercase">
										{formData.location || <Skeleton />}
									</p>
									<p className="text-uppercase">
										{formData.date || <Skeleton count={2} />}
									</p>
									{formData?.languages?.find(
										(language) => language.value === "English-UK"
									) && (
										<div
											style={{ cursor: "pointer" }}
											onClick={() => {
												const foundLanguage = formData.languages.find(
													(language) => language.value === "English-UK"
												);
												changeLanguage(foundLanguage);
											}}
											className="d-flex align-items-center"
										>
											<img
												className="me-2"
												style={{ width: "20px" }}
												src={ukLogo}
												alt="UK Flag"
											/>
											<p
												className={`pb-0 mb-0 ${
													selectedLanguage === "English-UK"
														? "text-green fw-bold"
														: ""
												}`}
											>
												English-UK
											</p>
										</div>
									)}
									{formData?.languages?.find(
										(language) => language.value === "English-US"
									) && (
										<div
											style={{ cursor: "pointer" }}
											onClick={() => {
												const foundLanguage = formData.languages.find(
													(language) => language.value === "English-US"
												);
												changeLanguage(foundLanguage);
											}}
											className="d-flex mt-2 align-items-center"
										>
											<img
												className="me-2"
												style={{ width: "20px" }}
												src={usaLogo}
												alt="USA Flag"
											/>
											<p
												className={`pb-0 mb-0 ${
													selectedLanguage === "English-US"
														? "text-green fw-bold"
														: ""
												}`}
											>
												English-US
											</p>
										</div>
									)}
									{formData?.languages?.find(
										(language) => language.value === "Italian"
									) && (
										<div
											style={{ cursor: "pointer" }}
											onClick={() => {
												const foundLanguage = formData.languages.find(
													(language) => language.value === "Italian"
												);
												changeLanguage(foundLanguage);
											}}
											className="d-flex mt-2 align-items-center"
										>
											<img
												className="me-2"
												style={{ width: "20px" }}
												src={italyLogo}
												alt="Italian Flag"
											/>
											<p
												className={`pb-0 mb-0 ${
													selectedLanguage === "Italian"
														? "text-green fw-bold"
														: ""
												}`}
											>
												Italian
											</p>
										</div>
									)}
									{formData?.languages?.find(
										(language) => language.value === "Spanish"
									) && (
										<div
											style={{ cursor: "pointer" }}
											onClick={() => {
												const foundLanguage = formData.languages.find(
													(language) => language.value === "Spanish"
												);
												changeLanguage(foundLanguage);
											}}
											className="d-flex mt-2 align-items-center"
										>
											<img
												className="me-2"
												style={{ width: "20px" }}
												src={spainLogo}
												alt="Spanish Flag"
											/>
											<p
												className={`pb-0 mb-0 ${
													selectedLanguage === "Spanish"
														? "text-green fw-bold"
														: ""
												}`}
											>
												Spanish
											</p>
										</div>
									)}
									{formData?.languages?.find(
										(language) => language.value === "Swiss-German"
									) && (
										<div
											style={{ cursor: "pointer" }}
											onClick={() => {
												const foundLanguage = formData.languages.find(
													(language) => language.value === "Swiss-German"
												);
												changeLanguage(foundLanguage);
											}}
											className="d-flex mt-2 align-items-center"
										>
											<img
												className="me-2"
												style={{ width: "20px" }}
												src={switzerlandLogo}
												alt="Switzerland Flag"
											/>
											<p
												className={`pb-0 mb-0 ${
													selectedLanguage === "Swiss-German"
														? "text-green fw-bold"
														: ""
												}`}
											>
												Swiss-German
											</p>
										</div>
									)}

									<h2 className="fs-5 mb-3 fw-bold mt-5 text-blue-new">
										{delagateManagerHeading || <Skeleton />}
									</h2>
									<p className="pb-0 mb-1 fw-bold">
										{formData.delegateManagerName || <Skeleton />}
									</p>
									<p className="pb-0 mb-1">
										{formData.delegateManagerPosition || <Skeleton />}
									</p>
									<a
										className="black-link d-block"
										href={`tel:${formData.delegateManagerPhone}`}
									>
										{formData.delegateManagerPhone || <Skeleton />}
									</a>
									<a
										className="black-link d-block"
										href={`mailto:${formData.delegateManagerEmail}`}
									>
										{formData.delegateManagerEmail || <Skeleton />}
									</a>
									{formData.secondaryDelegateManagerCode !== "" && (
										<div className="mt-4">
											<p className="pb-0 mb-1 fw-bold">
												{formData.secondaryDelegateManagerName}
											</p>
											<p className="pb-0 mb-1">
												{formData.secondaryDelegateManagerPosition}
											</p>
											<a
												className="black-link d-block"
												href={`tel:${formData.secondaryDelegateManagerPhone}`}
											>
												{formData.secondaryDelegateManagerPhone}
											</a>
											<a
												className="black-link d-block"
												href={`mailto:${formData.secondaryDelegateManagerEmail}`}
											>
												{formData.secondaryDelegateManagerEmail}
											</a>
										</div>
									)}
								</div>
							</Col>
							<Col xl={9}>
								<div
									style={{
										borderRadius: "8px",

										zIndex: 0,
									}}
									className="bg-white invite-div pb-4 position-relative "
								>
									<Sticky bottomBoundary="#content">
										<div
											className="pt-4 bg-white "
											style={{
												borderBottom: "1px solid #ADADAD",
												zIndex: 999,
											}}
										>
											{loadForm && renderStepList()}
											{!loadForm && (
												<div className="px-5 ">
													<Skeleton className="pt-5 mb-4" />
												</div>
											)}
										</div>
									</Sticky>
									<div
										style={{ zIndex: -1 }}
										className="position-relative px-4 px-lg-0"
									>
										<Row className="justify-content-center mt-5 mb-5">
											<Col>
												{loadForm && renderFormStep()}{" "}
												{!loadForm && (
													<div>
														<div className="px-5 ">
															<Skeleton className="pt-4" />
														</div>
														<div className="px-5 pt-4">
															<Skeleton className="pt-4" />
														</div>
														<div className="px-5 pt-4">
															<Skeleton className="pt-4" />
														</div>
														<div className="px-5 pt-4">
															<Skeleton className="pt-4" />
														</div>
														<div className="px-5 pt-4">
															<Skeleton className="pt-4" />
														</div>
														<div className="px-5 pt-4">
															<Skeleton className="pt-4" />
														</div>
														<div className="px-5 pt-4">
															<Skeleton className="pt-4" />
														</div>
														<div className="px-5 pt-4">
															<Skeleton className="pt-4" />
														</div>
														<div className="px-5 pt-4">
															<Skeleton className="pt-4" />
														</div>
														<div className="px-5 pt-4">
															<Skeleton className="pt-4" />
														</div>
														<div className="px-5 pt-4">
															<Skeleton className="pt-4" />
														</div>
														<div className="px-5 pt-4">
															<Skeleton className="pt-4" />
														</div>
													</div>
												)}
											</Col>
										</Row>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</section>
			<Footer />
		</Layout>
	);
};

export default CreateEventPage;
